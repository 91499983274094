import React, { useContext, useEffect, useState } from "react";
import useCampaigns, { DiscountCampaign } from "./useCampaigns";
import { FieldName, FieldPath, FieldValues, Path, useForm, UseFormReturn } from "react-hook-form";
import { LoginContext } from "../../contexts/LoginContext";
import FormErrorMessage from "../FormErrorMessage/FormErrorMessage";
import ReactModal from "react-modal";
import { devLog, logAndFetch } from "../../util";
import s from "./AddDiscountCode.module.scss";
import { FieldValuesFromFieldErrors } from "@hookform/error-message";
import { Affiliate } from "./affiliate";
import AddDiscountCodeSuccess from "./AddDiscountCodeSuccess";

const CODE_LIMITS = {
    minLength: 5,
    maxLength: 10,
}

ReactModal.setAppElement("#root");

const AddDiscountCode = () => {
    const { state: { loginToken } } = useContext(LoginContext);

    const campaigns = useCampaigns();
    const fetching = !campaigns;
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [processing, setProcessing] = useState(false);
    const [complete, setComplete] = useState(false);
    const [affiliate, setAffiliate] = useState<Affiliate>();

    const form = useForm<AddDiscountCodeFormFieldValues>({
        defaultValues: {
            discountAmount: NaN,
            camId: NaN,
            discountCode: "",
            description: "",
            expiresOn: undefined,
            requiresDisplayFrom: false,
            displayFrom: undefined,
            addAffiliate: false,
            name: "",
            email: "",
            currency: Currency.USD,
            commission: undefined,
            contractEndsOn: undefined,
        }
    });
    const { handleSubmit, watch, resetField, reset } = form;

    useEffect(() => {
        if (watch("requiresDisplayFrom")) return;
        resetField("displayFrom");
    }, [watch("requiresDisplayFrom")]);

    const addAffiliate = watch("addAffiliate");
    useEffect(() => {
        if (addAffiliate) return;
        resetField("name");
        resetField("email");
        resetField("currency");
        resetField("commission");
        resetField("contractEndsOn")
    }, [addAffiliate]);

    useEffect(() => {
        if (complete) {
            setProcessing(false);
            return;
        };
        setAffiliate(undefined);
        reset();
    }, [complete]);


    // const onSubmit = async (data: AddDiscountCodeFormFieldValues) => {
    //     setProcessing(true);
    //     await postDiscountCode(loginToken,data);
    //     if (data.addAffiliate) {
    //         const affiliate = await postAffiliate(loginToken,data);
    //         setAffiliate(affiliate);
    //     }
    //     setComplete(true);
    // }

    const onSubmit = async (data: AddDiscountCodeFormFieldValues) => {
        try {
            setProcessing(true);
            setErrorMessage(null); // Clear any previous error message
            await postDiscountCode(loginToken, data);
            if (data.addAffiliate) {
                const affiliate = await postAffiliate(loginToken, data);
                setAffiliate(affiliate);
            }
            setComplete(true);
        } catch (error) {
            console.error("Error submitting data:", error);

            if (error instanceof Error) {
                let errorMessage = error.message;

                try {
                    // Find the JSON part of the error message by extracting after the first '{'
                    const jsonStartIndex = errorMessage.indexOf('{');
                    if (jsonStartIndex !== -1) {
                        const jsonString = errorMessage.substring(jsonStartIndex);
                        const parsedError = JSON.parse(jsonString);
                        // If parsedError is an object and has a message, use it
                        if (parsedError && typeof parsedError === 'object' && 'error' in parsedError) {
                            errorMessage = parsedError.error.split('"')[0];
                        }
                    }
                } catch (parseError) {
                    // If parsing fails, just use the error message as is
                    setErrorMessage(`Submission failed: ${error.message}`);
                }

                setErrorMessage(`Submission failed: ${errorMessage}`);
            } else {
                setErrorMessage("Submission failed: An unknown error occurred");
            }
        } finally {
            setProcessing(false);
        }
    };




    return (
        <div className='admin-infosection'>
            <div className='container'>
                <caption>Add Discount Code</caption>
                <form className={s.addDiscountCodeForm} onSubmit={handleSubmit(onSubmit)}>
                    {errorMessage && (
                        <div className="error-message">
                            {errorMessage}
                        </div>
                    )}
                    <DiscountAmountInput form={form} />
                    <CampaignSelect form={form} campaigns={campaigns} />
                    <DiscountCodeInput form={form} />
                    <DescriptionInput form={form} />
                    <ExpiryDatetimeInput form={form} />
                    <LabelledCheckbox form={form} name="requiresDisplayFrom">Add Display-from Date?</LabelledCheckbox>
                    {watch("requiresDisplayFrom") && <DisplayFromDatetimeInput form={form} />}
                    <LabelledCheckbox form={form} name="addAffiliate" >Create New Affiliate?</LabelledCheckbox>
                    {watch("addAffiliate") && (
                        <div className={s.affiliatesInputs}>
                            <AffiliateNameInput form={form} />
                            <AffiliateEmailInput form={form} />
                            <AffiliateCurrencySelect form={form} />
                            <AffiliateCommissionInput form={form} />
                            <AffiliateContractEndsOnDateInput form={form} />
                        </div>
                    )}
                    <button type="submit" disabled={fetching || processing}>Submit</button>
                </form>
                <AddDiscountCodeSuccess affiliate={affiliate} discountCode={watch("discountCode")} complete={complete} setComplete={setComplete} />
            </div>
        </div>
    );
};

const CampaignSelect = ({ form, campaigns }:
    InputProps & { campaigns: DiscountCampaign[] | undefined }) => {
    const [campaignOptions, setCampaignOptions] = useState(campaigns);
    const [selectedCampaign, setSelectedCampaign] = useState<DiscountCampaign>();

    const { register, watch, resetField, setValue } = form;

    const discountPercentage = watch("discountAmount");
    const camId = watch("camId");

    useEffect(() => {
        setCampaignOptions(campaigns);
    }, [campaigns]);

    useEffect(() => {
        if (!campaignOptions) return;
        if (isNaN(camId)) setSelectedCampaign(undefined);
        setSelectedCampaign(campaignOptions.find(campaign => campaign.camId === camId));
    }, [camId]);

    /**
     * On changing campaign to one with a different discount, update discountAmount form value
     */
    useEffect(() => {
        const newPercentage = getPercentage(selectedCampaign);
        if (newPercentage === discountPercentage) return;
        if (selectedCampaign) setValue("discountAmount", newPercentage === undefined ? NaN : newPercentage);
    }, [selectedCampaign]);

    /**
     * On changing discount amount, set campaign options to only campaigns with that discount.
     * If discount amount is unset, show all campaigns as option and unset camId
     */
    useEffect(() => {
        if (!campaigns || !campaignOptions) return;
        if (isNaN(discountPercentage)) {
            setCampaignOptions(campaigns);
            resetField("camId");
            return;
        }
        if (getPercentage(selectedCampaign) === discountPercentage) return;
        setCampaignOptions(campaigns.filter(campaign => campaign.amount !== undefined && getPercentage(campaign) === discountPercentage));
        resetField("camId");
    }, [discountPercentage]);

    return (
        <div className={s.inputContainer}>
            <label>
                <span>Select Campaign:</span>
                <select defaultValue="" disabled={!campaigns} {...register("camId", {
                    required: "Campaign is required",
                    valueAsNumber: true
                })}>
                    <option value="">{campaigns ? (campaignOptions && campaignOptions.length ? "" : "No campaigns found for discount") : "Loading campaigns..."}</option>
                    {campaignOptions && campaignOptions.map((c, i) => <option key={i + 1} value={c.camId}>{c.label}</option>)}
                </select>
            </label>
            <FormErrorMessage form={form} name="camId" />
        </div>
    );
}

const DiscountAmountInput = ({ form }: InputProps) => (
    <div className={s.discountAmount}>
        <label>
            <span>Discount Amount:</span>
            <span>
                <input type="number" {...form.register("discountAmount", {
                    required: "Discount amount is required",
                    valueAsNumber: true,
                })} />%
            </span>
        </label>
    </div>
);

const DiscountCodeInput = ({ form }: InputProps) => {
    const { register, watch, setValue } = form;

    useEffect(() => {
        setValue("discountCode", watch("discountCode").toUpperCase());
    }, [watch("discountCode")]);

    return (
        <div className={s.inputContainer}>
            <label>
                <span>Discount Code:</span>
                <input type="text" {...register("discountCode", {
                    required: "Discount code is required",
                    minLength: {
                        value: CODE_LIMITS.minLength,
                        message: `Discount code must be more than ${CODE_LIMITS.minLength - 1} characters`,
                    },
                    maxLength: {
                        value: CODE_LIMITS.maxLength,
                        message: `Discount code must be less than ${CODE_LIMITS.maxLength + 1} characters`,
                    },
                    pattern: {
                        value: new RegExp(String.raw`^[A-Z0-9]{${CODE_LIMITS.minLength},${CODE_LIMITS.maxLength}}$`),
                        message: "Discount code must only contain uppercase A-Z and numbers 0-9",
                    }
                })} />
            </label>
            <label className={s.hiddenLabelRightCentered}>
                <span>Generate Random Discount Code</span>
                <span><button type="button" onClick={e => randomDiscountCode().then(code => setValue("discountCode", code))}>Random Discount Code</button></span>
            </label>
            <FormErrorMessage form={form} name="discountCode" />
        </div>
    );
}

const DescriptionInput = ({ form }: InputProps) => (
    <div className={s.inputContainer}>
        <label>
            <span>Description:</span>
            <input type="text" {...form.register("description", { required: "Description is required" })} />
        </label>
        <FormErrorMessage form={form} name="description" />
    </div>
);

const ExpiryDatetimeInput = ({ form }: InputProps) => {
    const { register } = form;
    const defaultValue = time.midnightString(time.nDaysFrom(new Date(), 30));
    return (
        <div className={s.inputContainer}>
            <label>
                <span>Expiry Date:</span>
                <input type="datetime-local" defaultValue={defaultValue} min={time.inputMin()}
                    {...register("expiresOn", {
                        required: "Expiry date is required",
                        valueAsDate: true,
                        validate: (val) => {
                            if (val.toString() === "Invalid Date") return "Expiry date is required";
                            if (val < new Date()) return "Expiry date must be in the future";
                            return true;
                        },
                    })}
                />
            </label>
            <TimeZoneLabel />
            <FormErrorMessage form={form} name="expiresOn" />
        </div>
    )
}

const TimeZoneLabel = () => (
    <label className={s.hiddenLabelRightCentered}>
        <span>Time Zone</span>
        <span>({time.timeZone()})</span>
    </label>
)

type LabelledCheckboxProps<TFieldValues extends FieldValues> = {
    form: UseFormReturn<TFieldValues, any>,
    name: FieldPath<TFieldValues>,
} & Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "type" | "form" | "name">

function LabelledCheckbox<TFieldValues extends FieldValues>({ form, name, children, ...rest }: LabelledCheckboxProps<TFieldValues>) {
    return (
        <div className={s.checkboxContainer}>
            <label>
                <input type="checkbox" {...form.register(name)} {...rest} />
                <span>{children}</span>
            </label>
            <FormErrorMessage form={form} name={name as unknown as FieldName<FieldValuesFromFieldErrors<TFieldValues>>} />
        </div>
    )
}

const DisplayFromDatetimeInput = ({ form }: InputProps) => {
    const { register, getValues } = form;
    const defaultValue = time.midnightString(new Date());
    return (
        <div className={s.inputContainer}>
            <label>
                <span>Display-from Date:</span>
                <input type="datetime-local" defaultValue={defaultValue}
                    {...register("displayFrom", {
                        required: "Display-from date is required",
                        valueAsDate: true,
                        validate: (val) => {
                            if (!time.isValidDate(val)) return "Display-from date is required";
                            if (!time.isValidDate(getValues("expiresOn")) || val > getValues("expiresOn")) return "Display from date must be before expiry date";
                            return true;
                        }
                    })}
                />
            </label>
            <TimeZoneLabel />
            <FormErrorMessage form={form} name="displayFrom" />
        </div>
    )
}

const AffiliateNameInput = ({ form }: InputProps) => (
    <div className={s.inputContainer}>
        <label>
            <span>Affiliate Name:</span>
            <input type="text" {...form.register("name", { required: form.getValues("addAffiliate") && "Name is required" })} />
        </label>
        <FormErrorMessage form={form} name="name" />
    </div>
);

const AffiliateEmailInput = ({ form }: InputProps) => {
    const { register, watch, setValue, getValues } = form;

    const email = watch("email");
    useEffect(() => {
        setValue("email", email.toLowerCase());
    }, [email]);

    return (
        <div className={s.inputContainer}>
            <label>
                <span>Affiliate Email:</span>
                <input type="text" {...register("email", {
                    required: !!getValues("addAffiliate") && "Email is required",
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address"
                    }
                })} />
            </label>
            <FormErrorMessage form={form} name="email" />
        </div>
    );
}

const AffiliateCurrencySelect = ({ form }: InputProps) => {
    const currencies = Object.values(Currency).sort((a, b) => a < b ? -1 : 1);
    return (
        <div className={s.inputContainer}>
            <label>
                <span>Select Currency:</span>
                <select {...form.register("currency", { required: !!form.getValues("addAffiliate") && "Currency is required" })}>
                    <option value=""></option>
                    {currencies.map((cur, i) => <option key={i} value={cur}>{cur}</option>)}
                </select>
            </label>
            <FormErrorMessage form={form} name="currency" />
        </div>
    );
}

const AffiliateCommissionInput = ({ form }: InputProps) => (
    <div className={s.discountAmount}>
        <label>
            <span>Commission:</span>
            <span><input type="number" {...form.register("commission", {
                required: form.getValues("addAffiliate") && "Commission is required",
                min: {
                    value: 0,
                    message: "Commission cannot be less than 0%",
                },
                max: {
                    value: 100,
                    message: "Commission cannot be greater than 100%",
                }
            })} />%</span>
        </label>
        <FormErrorMessage form={form} name="commission" />
    </div>
);

const AffiliateContractEndsOnDateInput = ({ form }: InputProps) => {
    const { register, getValues } = form;
    const defaultDate = time.isoDate(time.nYearsFrom(new Date(), 2));
    return (
        <div className={s.inputContainer}>
            <label>
                <span>Contract Ends On:</span>
                <input type="date" defaultValue={defaultDate} min={time.inputMin()} {...register("contractEndsOn", {
                    required: "Contract end-date is required",
                    validate: (val) => {
                        if (val <= time.isoDate(new Date())) return "Contract end-date must be in the future";
                        if (val <= time.isoDate(getValues("expiresOn"))) return "Contract end-date must be after discount code expiry date";
                        if (getValues("displayFrom") && val <= time.isoDate(getValues("displayFrom"))) return "Contract end-date must be after display-from date";
                        return true;
                    }
                })}
                />
            </label>
            <TimeZoneLabel />
            <FormErrorMessage form={form} name="contractEndsOn" />
        </div>
    )
}

/* DATE AND TIME FUNCTIONS */

/**
 * Functions used for parsing dates for use with date and datetime-local inputs
 */
const time = {
    timeZone: (): string => Intl.DateTimeFormat().resolvedOptions().timeZone,
    inputString: (date: Date): string => {
        const [d, t] = date.toISOString().split("T");
        const [h, m, s] = t.substring(0, t.length - 1).split(":");
        const result = `${d}T${h}:${m}`;
        return result;
    },
    inputMin: (): string => time.inputString(new Date()),
    isoDate: (date: Date): string => date.toISOString().split("T")[0],
    nDaysFrom: (date: Date, n: number): Date => new Date(date.getTime() + n * 24 * 60 * 60 * 1000),
    nYearsFrom: (date: Date, n: number): Date => new Date(date.getTime() + n * 365 * 24 * 60 * 60 * 1000),
    midnightString: (date: Date): string => `${time.isoDate(date)}T00:00`,
    isValidDate: (date: Date): boolean => date instanceof Date && date.toString() !== "Invalid Date",
    utcFromDateString: (s: string): number => {
        const [y, m, d] = s.split("-").map(v => parseInt(v));
        return Date.UTC(y, m, d, 0, 0, 0, 0);
    }
}

/* API */

const fetchDiscountCode = async (code: string): Promise<DiscountCode | undefined> => {
    const res = await logAndFetch(`/api/discount-codes?discountCode=${code}`);
    const rows: DiscountCodeBackend[] | undefined = await res.json();
    const discountCode = rows ? rows[0] : undefined;
    const result = discountCode ? parseDiscountCode(discountCode) : undefined;
    return result;
}

const parseDiscountCode = (discountCode: DiscountCodeBackend): DiscountCode => {
    const { dis_id, cam_id, cid, created_on_ms, discount_code, expires_on_ms, esid, description, updated_on_ms, display_from_ms } = discountCode;
    return {
        disId: dis_id,
        camId: cam_id ? cam_id : undefined,
        cid: cid ? cid : undefined,
        createdOnMs: created_on_ms ? created_on_ms : undefined,
        discountCode: discount_code ? discount_code : undefined,
        expiresOnMs: expires_on_ms ? expires_on_ms : undefined,
        esid: esid ? esid : undefined,
        description: description ? description : undefined,
        updatedOnMs: updated_on_ms ? updated_on_ms : undefined,
        displayFromMs: display_from_ms ? display_from_ms : undefined,
    }
}

const postAffiliate = async (loginToken: string | undefined, data: AddDiscountCodeFormFieldValues): Promise<Affiliate | undefined> => {
    if (!loginToken) throw Error("Login token is undefined");
    const { camId, discountCode, contractEndsOn, addAffiliate, name, email, currency, commission } = data;
    const body: PostAffiliateBody = {
        token: loginToken,
        discountCode,
        camId,
        contractEndMs: time.utcFromDateString(contractEndsOn),
        name,
        email,
        currency,
        commission: parseFloat((commission / 100).toFixed(2)),
    }
    const res = await logAndFetch("/api/affiliates", {
        method: "POST",
        body: JSON.stringify(body),
    });
    const { affiliate }: { affiliate: Affiliate | undefined } = await res.json();
    if (affiliate) devLog(`Affiliate inserted with affil_id=${affiliate.affilId}`);
    return affiliate;
}

// const postDiscountCode = async (loginToken: string | undefined, data: DiscountCodeData) => {
//     if (!loginToken) throw Error("Login token not found");
//     const { camId, discountCode, description, expiresOn, displayFrom } = data;
//     const body: PostDiscountCodeBody = {
//         token: loginToken,
//         camId,
//         discountCode,
//         description,
//         expiresOnMs: expiresOn.getTime(),
//         displayFromMs: displayFrom ? displayFrom.getTime() : undefined,
//     }
//     alert("PostDiscountCode");
//     const res = await logAndFetch("/api/discount-codes", {
//         method: "POST",
//         body: JSON.stringify(body),
//     });
//     const { disId, postId, error }: { disId: number | undefined, postId: number | undefined, error: string | undefined } = await res.json();
//     if (error && !disId && !postId) {
//         console.error(error);
//         return undefined;
//     }
//     devLog(`Discount code inserted with dis_id=${disId}, post_id=${postId}`);
//     return { disId, postId };
// }


const postDiscountCode = async (loginToken: string | undefined, data: DiscountCodeData) => {
    if (!loginToken) throw new Error("Login token not found");

    const { camId, discountCode, description, expiresOn, displayFrom } = data;
    const body: PostDiscountCodeBody = {
        token: loginToken,
        camId,
        discountCode,
        description,
        expiresOnMs: expiresOn.getTime(),
        displayFromMs: displayFrom ? displayFrom.getTime() : undefined,
    };


    try {
        const res = await logAndFetch("/api/discount-codes", {
            method: "POST",
            body: JSON.stringify(body),
        });


        // Check if the response status is not OK (e.g., 200)
        if (!res.ok) {
            const errorText = await res.text(); // Get the error message from the response body
            console.error(`Error: ${res.status} - ${errorText}`);
            throw new Error(`HTTP error! status: ${res.status} - ${errorText}`);
        }

        const { disId, postId, error }: { disId: number | undefined, postId: number | undefined, error: string | undefined } = await res.json();

        if (error && !disId && !postId) {
            console.error(error);
            return undefined;
        }

        devLog(`Discount code inserted with dis_id=${disId}, post_id=${postId}`);
        return { disId, postId };

    } catch (err) {
        console.error("Failed to post discount code:", err);
        throw err;
    }
};


/* UTIL */

const getAmount = (campaign: DiscountCampaign | undefined) => campaign === undefined ? undefined : campaign.amount;

const getPercentage = (campaign: DiscountCampaign | undefined) => {
    const amount = getAmount(campaign);
    if (amount === undefined) return undefined;
    return parseInt((amount * 100).toFixed(0));
}

const randomAlphaNumericString = (nChars: number): string => {
    const characters = "ABCDEFGHJKMNPQRSTUVWXYZ23456789"; // avoid ambiguous characters: I,L,1, and 0,O
    let result = "";
    for (let i = 0; i < nChars; i++) result += characters.charAt(Math.floor(Math.random() * characters.length));
    return result;
}

const randomDiscountCode = async () => {
    const randomStringLength = 6;
    while (true) {
        const result = randomAlphaNumericString(randomStringLength);
        const discountCode = await fetchDiscountCode(result);
        if (!discountCode) return result;
    }
}

/* TYPES */

enum Currency {
    EUR = "EUR",
    USD = "USD",
    GBP = "GBP",
    CAD = "CAD",
    AUD = "AUD",
    NZD = "NZD",
}

interface AddDiscountCodeFormFieldValues {
    discountAmount: number;
    camId: number;
    discountCode: string;
    description: string;
    expiresOn: Date;
    requiresDisplayFrom: boolean;
    displayFrom: Date;
    addAffiliate: false;
    name: string;
    email: string;
    currency: Currency;
    commission: number;
    contractEndsOn: string;
}

type DiscountCodeFormReturn = UseFormReturn<AddDiscountCodeFormFieldValues, any>;

interface InputProps {
    form: DiscountCodeFormReturn;
}

interface DiscountCodeBackend {
    dis_id: number;
    cam_id: number | null;
    cid: number | null;
    created_on_ms: number | null;
    discount_code: string | null;
    expires_on_ms: number | null;
    esid: number | null;
    description: string | null;
    updated_on_ms: number | null;
    display_from_ms: number | null;
}

interface DiscountCode {
    disId: number;
    camId: number | undefined;
    cid: number | undefined;
    createdOnMs: number | undefined;
    discountCode: string | undefined;
    expiresOnMs: number | undefined;
    esid: number | undefined;
    description: string | undefined;
    updatedOnMs: number | undefined;
    displayFromMs: number | undefined;
}


interface DiscountCodeData {
    camId: number;
    discountCode: string;
    description: string;
    expiresOn: Date;
    displayFrom: Date | undefined;
}

interface PostDiscountCodeBody {
    token: string,
    camId: number,
    cid?: number | undefined,
    discountCode: string,
    description: string,
    expiresOnMs: number,
    displayFromMs?: number | undefined,
    esid?: number | undefined,
}

interface PostAffiliateBody {
    token: string,
    discountCode: string,
    camId: number,
    contractEndMs: number,
    name: string,
    email: string,
    currency: Currency,
    commission: number,
}

export default AddDiscountCode;